<template>
  <div class="ticket">
    <iframe v-if="hash"
      style="width: 100%; height: 1400px;"
      :src="'https://www.msup.com.cn/shopping/default/shopping-tickect?sid=3151&qd='+hash"
    ></iframe>
    <iframe v-if="!hash"
      style="width: 100%; height: 1400px;"
      src="https://www.msup.com.cn/shopping/default/shopping-tickect?sid=3151&qd="
    ></iframe>
  </div>
</template>

<script>

export default {
 data(){
  return {
   hash:''
  }
 },
 created(){
  this.hash = this.global.hashParam["qd"]
 }
};
</script>

<style lang="scss" scoped>
.ticket{
  margin-top: -30px;
}
@media (max-width: 1023px) {
  .ticket{
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3000;
  }
}
</style>